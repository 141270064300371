import { Pagination } from "constants/type"
import { AdminActivityUserModel } from "services/activity/type"
import { Transaction } from "services/total/types"
import { CardGateway, Comments } from "types/api"

export interface IUserService {
  getUser: () => Promise<User | void>
  editUser: (id: string, payload: EditUserPayload) => Promise<EditUserResponse | void>
  editUserBalance: (id: string, payload: EditUserBalancePayload) => Promise<EditUserBalanceResponse[] | void>
  getUserBets: GetUserBets
  getUsersTable: GetUserTableHandler
  getUserReferrals: GetUserReferrals
  getUserTransactions: GetUserTransactions
  getUserRewardGames: GetUserRewardGames
  getUserById: (id: string) => Promise<GetSingleUser | void>
  addUserRewardGames: (id: string, gameIdentifier: any) => Promise<any>
  addComment: (id: string, comment: string, category: string) => Promise<any>

  getUserBonuses: GetUserBonuses
}

export type GetUserTableHandler = (
  page: number,
  pageSize: number,
  id: number | undefined,
  filter: { q: string; createdAt: (Date | null)[] },
  sortRow?: string,
  sortOrder?: string
) => Promise<UsersTableResponse | void>

export type GetUserBets = (
  page: number,
  pageSize: number,
  id: number | undefined,
  filters: any,
  sortRow?: string,
  sortOrder?: string
) => Promise<GetUserBetsResponse | void>
export type GetUserReferrals = (
  page: number,
  pageSize: number,
  id: number | undefined,
  filters: any,
  sortRow?: string,
  sortOrder?: string
) => Promise<GetUserReferralsResponse | void>
export type GetUserTransactions = (
  page: number,
  pageSize: number,
  id: number | undefined,
  filter: { type: string; status: string },
  sortRow?: string,
  sortOrder?: string
) => Promise<GetUserTransactionsResponse | void>
export type GetUserRewardGames = (
  page: number,
  pageSize: number,
  id: number | undefined,
  filters: any,
  sortRow?: string,
  sortOrder?: string
) => Promise<GetUserRewardGamesResponse | void>
export type GetUserBonuses = (
  page: number,
  pageSize: number,
  id: string | undefined,
  filters: any,
  sortRow?: string,
  sortOrder?: string
) => Promise<Paginated<UserBonus[]> | void>

export type User = {
  avatar_url: string | null
  display_name: string | null
  username: string
  email_address: string
  email_verified: boolean
  phone_number: string | null
  phone_number_verified: boolean
  two_fa_code: null | boolean
  use_two_fa: boolean
  created_at: Date
  rakeback_balance: number
  rakeback_earned: number
  discord_name: null | string
  role: UserRole
  wallet_coin: Coin
  referral_links: string[]
  wallets: Wallets
  personal_profile: PersonalProfile
  user_access: UserAccess
  user_settings: UserSettings
}

export type PersonalProfile = {
  id: number
  user_id: number
  first_name: string | null
  last_name: string | null
  dob: string | null
  gender: "Male" | "Female" | null
  address: string | null
  address2: string | null
  city: string | null
  state: string | null
  zip: string | null
  country: string | null
  created_at: Date
  updated_at: Date
}

export type Wallets = {
  id: number
  user_id: number
  coin: Coin
  address: null | string
  balance: string
  used: boolean
  last_deposit_date: null | Date
  created_at: Date
  updated_at: Date
}

export type UserAccess = {
  id: number
  user_id: number
  deposit: boolean
  withdraw: boolean
  games: boolean
  chat: boolean
  chat_ban_expire: null | Date
  created_at: Date
  updated_at: Date
}

export type UserSettings = {
  id: number
  user_id: number
  incognito: boolean
  allow_marketing: boolean
  allow_notifications: boolean
  created_at: Date
  updated_at: Date
}

export enum UserRole {
  ADMIN = "ADMIN",
  MOD = "MOD",
  SUPPORT = "SUPPORT",
  INFLUENCER = "INFLUENCER",
  VIP = "VIP",
  USER = "USER"
}
export enum Coin {
  BTC = "BTC",
  DOGE = "DOGE",
  XRP = "XRP",
  BNB = "BNB",
  MATIC = "MATIC",
  LTC = "LTC",
  TRX = "TRX",

  SHIB = "SHIB",
  SHIB_BSC = "SHIB_BSC",

  ETH = "ETH",
  ETH_BASE = "ETH_BASE",
  ETH_ARBITRUM = "ETH_ARBITRUM",
  ETH_BSC = "ETH_BSC",

  USDT = "USDT",
  USDT_TRX = "USDT_TRX",
  USDT_POLYGON = "USDT_POLYGON",
  USDT_BSC = "USDT_BSC",
  USDT_ARBITRUM = "USDT_ARBITRUM",

  USDC = "USDC",
  USDC_BASE = "USDC_BASE",
  USDC_ARBITRUM = "USDC_ARBITRUM",
  USDC_POLYGON = "USDC_POLYGON",
  USDC_BSC = "USDC_BSC",
  USDC_TRX = "USDC_TRX",

  USD = "USD",
  B_USD = "B_USD"
}

export type UsersTableResponse = Pagination & {
  data: SingleUserTableModel[]
}

export type SingleUserTableModel = {
  id: string
  display_name: string
  username: string
  email_address: string
  phone_number: string
  discord_name: null | string
  email_verified: boolean
  activated: boolean
  kyc: boolean
  use_two_fa: boolean
  role: UserRole
  level: number
  rakeback_earned: number
  rakeback_balance: number
  trusted_level: number
  trusted_status: string
  bets: []
  created_at: Date
  Affiliate: Affiliate[]
  referral_links: []
  referrals: number
  wager: string
  wins: string
  coins: {
    [key in keyof typeof Coin]: number
  }
  is_referred: boolean
  affiliate_earned: number
  affiliate_balance: number
  influencer_percent: null | number
  affiliate_percent: number
  eth_balance: string
  ltc_balance: string
  btc_balance: string
  usdt_balance: string
  usd_balance: string
  busd_balance: string
  balance: string
  comments: Comments[]
  next_card_gateway?: CardGateway
  tenant_id?: string
  gameSessions: GameSessions[]
}

export type GameSessions = {
  id: string
  user_id: string
  short_id: string
  created_at: string
  updated_at: string
  currency: string
}

export type Affiliate = {
  code: string
  influencer_percent: null | number
  balance: number
  total_earned: number
  return_percent: number
  _count: {
    referral_links: number
  }
}

export type GetSingleUser = SingleUserTableModel & {
  wallet_coin: string
  total_deposit_amount: number
  total_withdrawal_amount: number
  total_pnl: number
  total_reward_amount: number
  personal_profile: PersonalProfile
  last30DaysWagered: number
  last30DaysDeposits: number
  last30DaysPNL: number
  last7DaysPNL: number
}
export type UserTransaction = Omit<Transaction, "updated_at" | "user_id" | "user" | "withdrawal_address" | "payout_id">

export type Paginated<T> = Pagination & { data: T }
export type GetUserTransactionsResponse = Pagination & {
  data: UserTransaction[]
}

export type GetUserBetsResponse = Pagination & {
  data: any
}

export type GetUserReferralsResponse = Pagination & {
  data: any
}

export type GetUserRewardGamesResponse = Pagination & {
  data: any
}

export type GetUserCommentsResponse = Pagination & {
  data: any
}

export type EditUserResponse = AdminActivityUserModel

export type EditUserPayload = {
  username: string
  first_name: string
  last_name: string
  display_name?: string
  email_address: string
  phone_number: string
  dob: string | null
  activated: boolean
  email_verified: boolean
  role: UserRole
  country: string
  level: number
  rakeback_balance: number
  affiliate_balance: number
  influencer_percent: number | null
  affiliate_percent: number
}

export type EditUserBalanceResponse = {
  address: null | string
  balance: string
  coin: string
  created_at: string | Date
  id: number
  last_deposit_date: null | string | Date
  updated_at: string
  used: boolean
  user_id: number
}

export type EditUserBalancePayload = {
  BTC: number
  USDT: number
  ETH: number
  LTC: number
  USD: number
  B_USD: number
}

export enum BonusStatus {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
  TRANSFERRED = "TRANSFERRED"
}

export type UserBonus = {
  id: string
  bonus_id: string
  user_id: string
  bonus_amount: number
  wagered_amount: number
  wager_requirements: number
  transferred_amount: number
  deposit_amount?: number
  expires_at: string
  status: BonusStatus
  created_at: string
  updated_at: string
}
